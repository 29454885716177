$orange: #ff6f00;

@mixin b($color) {
  border: 1px solid $color;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'jaf-facitweb', sans-serif;
  font-style: normal;
  font-weight: 200;
  max-width: 100%;
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 8px;
  font-weight: bold;
  @media only screen and (max-width: 1251px) {
    font-size: 30px;
  }
}

h3 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 24px;
  @media only screen and (max-width: 1251px) {
    font-size: 20px;
  }
}

p {
  font-size: 1.2rem;
  @media only screen and (max-width: 1251px) {
    font-size: 15px;
  }
}

// .app {
  // height: 100%;
  // overflow: hidden;
  // width: 100vw;
  // display: flex;
  // justify-content: center;
// }

.main-div {
  display: flex;
  flex-flow: column;
  align-items: center;
  // position: absolute;
  // top: 0;
  // width: 100%;
  // max-width: 1500px;
  z-index: 10;
}



.orangeback { background: linear-gradient(90deg, #C93B17 0%, #FC6F02 100%); }
.blue1back { background: #001AB1; }
.blue2back { background: #132BC2; }
.blue3back { background: #3852D1; }
.blue4back { background: #5D79E1; }
.blue5back { background: #81A0F0; }
.blue6back { background: #A6C7FF; }

@import './footer';
@import './header';
@import './homepage';

// ******** LOADER ********

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  // height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  // top: 33px;
  margin-top: -17px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* fade stuff */

.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform .9s ease-out;
  will-change: opacity, visibility;
}

.fade-in-right {
  opacity: 0;
  transform: translateX(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform .9s ease-out;
  will-change: opacity, visibility;
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform .9s ease-out;
  will-change: opacity, visibility;
}

.fade-in-top {
  opacity: 0;
  transform: translateY(-10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform .9s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible, .fade-in-left.is-visible, .fade-in-right.is-visible, .fade-in-top.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}