.home-section {
  width: 100%;
  display: flex;
  align-items: center;
}

// .home-text {
//   width: 500px;
// }

// ==== ==== ==== SECTION 1 ==== ==== ====
// ==== ==== ==== SECTION 1 ==== ==== ====

.section-1 {
  justify-content: space-between;
  width: 100%;
}

.section-1_text {
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  h2 {
    @media only screen and (max-width: 1090px) {
      font-size: 24px;
    }
  }
  h3 {
    font-size: 24px;
    @media only screen and (max-width: 1090px) {
      font-size: 14px;
    }
  }
  p {
    @media only screen and (max-width: 1090px) {
      font-size: 12px;
      // position: absolute;
      // width: 90%;
    }
  }
  @media only screen and (max-width: 1090px) {
    width: 50%;
    margin-top: -80px;
  }
  @media only screen and (max-width: 1253px) {
    margin-top: 20px;
  }
}

// .section-1_background {
//   height: 750px;
//   width: 590px;
//   overflow: hidden;
//   position: relative;
// }

.section-1_image {
  justify-self: flex-end;
  margin-top: -105px;
  // position:absolute;
  // right: 0;
  height: 850px;
  @media only screen and (max-width: 1251px) {
    height: 450px;
    width: 40%;
  }
  @media only screen and (max-width: 800px) {
    margin-top: 0px;
    margin-right: -5px;
  }
}


// ==== ==== ==== SECTION 2 ==== ==== ====
// ==== ==== ==== SECTION 2 ==== ==== ====

.section-2 {
  margin-top: -160px;
  z-index: 1;
  @media only screen and (max-width: 1251px) {
    margin-top: -50px;
  }
  @media only screen and (max-width: 610px) {
    // margin-top: -300px
    margin-bottom: 200px;
  }
}

.section-2_text {
  margin-top: 400px;
  margin-left: -100px;
  margin-right: 70px;
  width: 40%;
  h2 {
    @media only screen and (max-width: 800px) {
      font-size: 24px;
    }
  }
  h3 {
    font-size: 24px;
    @media only screen and (max-width: 800px) {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 800px) {
    width: 80%;
    position: absolute;
    margin-left: 10%;
    margin-top: 1100px;
    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        margin-top: 350px;
    }}
  }
  @media only screen and (max-width: 600px) {
    margin-top: 600px;
    // @media not all and (min-resolution:.001dpcm)
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      margin-top: 350px;
    }
  }
}

.section-2_image {
  z-index: 1000;
  @media only screen and (max-width: 600px) {
    height: 450px;
    width: 70%;
  }
}

// ==== ==== ==== SECTION 3 ==== ==== ====
// ==== ==== ==== SECTION 3 ==== ==== ====

.section-3 {
  position: relative;
  margin-top: -580px;
  background-image: url('./Assets/Stripes.svg');
  background-size: cover;
  background-position: right center;
  overflow: visible;
  padding-bottom: 900px;
  @media only screen and (max-width: 1251px) {
    margin-top: 0;
    background-image: none;
    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        margin-top: 0px;
    }}
  }
  @media only screen and (max-width: 620px) {
    background-image: url('./Assets/Stripes.svg');
  }
}

// .section-3_wrapper {
//   max-width: 1500px;
//   align-items: center;
//   justify-content: center;
//   flex-direction: row;
// }

.section-3_video-wrapper {
  position: absolute;
  margin-top: 1000px;
  margin-left: 13%;
  @media only screen and (max-width: 800px) {
    width: 100%;
    margin-top: 1250px;
    margin-left: 10%;
  }
  @media only screen and (max-width: 610px) {
    width: 90%;
    margin-left: 3.7%;
    height: 600px;
    border-width: 10px;
    margin-top: 700px;
    margin-bottom: 10px;
  }
}

.section-3_video-container {
  height: 800px; width: 720px;
  padding: 24px;
  background-image: url('./Assets/phone.svg');
  // background-size: cover;

  h3 { 
    font-size: 2.4rem;
    @media only screen and (max-width: 610px) {
      text-align: center;
    }
  }

  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      margin-top: -700px;
  }}

  @media only screen and (max-width: 610px) {
    // height: 600px;
    background-image: none;
    width: 100%;
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      margin-top: -300px;
    }
  }
}

.section-3_image-medium_screens {
  margin-left: 25%;
  @media only screen and (max-width: 1251px) {
    height: 450px;
    display: none;
  }
}

.section-3_video {
  width: 80%; height: 74%;
  margin-top: 4px;
  margin-left: 70.2px;
  @media only screen and (max-width: 610px) {
    // height: 600px;
    border-width: 10px;
    background-image: none;
    width: 100%;
    height: auto;
    margin: 0;
    border: 15px solid #001ab1;
    border-radius: 10px;
    // background-color: #001ab1;
  }
}

.section-3_video-squares {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}

.section-3_video-square {
  height: 46px; width: 46px;
  margin-right: 8px;
  margin-left: 8px;
  @media only screen and (max-width: 600px) {
    height: 30px;
  }
}

.section-3_video-circle {
  height: 46px; width: 46px;
  border-radius: 50%;
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

// ==== ==== ==== SECTION 4 ==== ==== ====
// ==== ==== ==== SECTION 4 ==== ==== ====

.section-4 {
  margin-top: -600px;
  flex-flow: column;
  align-items: center;
  @media only screen and (max-width: 1251px) {
    margin-top: -50px;
  }
  @media only screen and (max-width: 800px) {
    margin-top: 30px;
  }
  @media only screen and (max-width: 400px) {
    margin-top: -200px;
  }
}

// ==== ==== ==== SECTION 4 CARD ==== ==== ====
// ==== ==== ==== SECTION 4 CARD ==== ==== ====
.scene {
  width: 65%;
  max-width: 1000px;
  height: 500px;
  perspective: 1000px;
  margin-bottom: 64px;
  @media only screen and (max-width: 1251px) {
    display: none;
  }
}

.section-4_card {
  position: relative;
  transition: transform .8s;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  // overflow: hidden;
  &:hover { cursor: pointer; }
}

.is-flipped {
  transform: rotateY(180deg);
}

.card_inner {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  // visibility: visible;
  // z-index: 1000;
  
  overflow: hidden;
  padding: 0 32px;
}

.card-1 {
  border: 8px solid #001AB1;
  background-color: #fff;
}

.card-2 {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #001AB1;
  border: 8px solid white;
  transform: rotateY(180deg);
}

.card_top {
  width: 100%;
  display: flex;
}

.card_text {
  margin-top: 48px;
  width: 60%;
  font-weight: bold;
}

.card_statements {
  color: white;
  width: 100%;
  padding: 0 120px;
  text-align: center;
  margin-top: 64px;
  font-weight: bold;
  font-size: 30px;
}

.card_letter {
  font-size: 9rem; font-weight: bold;
  color: $orange;
  margin-right: 32px;
}

.card_hook {
  color: #001ab1;
  font-size: 1.4rem;
}

.card_hook-2 {
  color: white;
  font-size: 1.2rem;
}

.card_bottom {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  bottom: -4px;
  left: 0;
  width: 100%;

  backface-visibility: hidden;
}

.card_flip {
  width: 150px;
  height: 150px;
}

.orangeFlip {
  width: 150px;
  height: 150px;
  position: absolute;
  right: 0;
}

.card_image { 
  width: 45%;
  margin-right: -10px;
  margin-bottom: -10px;
}

.section-4_mobile-card {
  width: 90%;
  border: 5px solid #001ab1;
  margin-bottom: 30px;
  z-index: 10000;
  background-color: #fff;
  overflow: hidden;

  @media only screen and (min-width: 1251px) {
    display: none;
  }
}

.mobile-card_header {
  display: flex;
  padding: 20px;
}

.mobile-card_letter {
  font-weight: bold;
  color: $orange;
  font-size: 5rem;
}

.mobile-card_title {
  margin-left: 10px;
  font-size: 25px;
}

.mobile-card_tagline {
  margin-left: 15px;
  font-size: 14px;
}

.mobile-card_description {
  margin-bottom: 20px;
  font-size: 18px;
}

.mobile-card_hook {
  color: #1a50e4;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: left;
}

.mobile-card_main {
  padding: 0px 10px;
  margin-bottom: 20px;
}

.mobile-card_tags {
  display: inline-block;
}

.mobile-card_tag {
  color: #fff;
  background-color: #001ab1;
  font-weight: bold;
  width: max-content;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  float: left;
}

.mobile-card_footer {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}

.mobile-card_image {
  max-width: 300px;
  max-height: 300px;
  object-fit: cover;
  margin-bottom: -3px;
  margin-right: -6px;
}

// ==== ==== ==== SECTION 5 ==== ==== ====
// ==== ==== ==== SECTION 5 ==== ==== ====

.section-5 {
  flex-flow: column;

  @media only screen and (min-width: 1500px) {
    background-color: #001ab1;
  }
}

.section-5_top {
  display: flex;
  justify-content: space-between;
  width: 60%;
  max-width: 1000px;
  
  span {
    font-size: 2rem;
    font-weight: bold;
    width: 200px;
    text-align: center;
    // margin-right: 140px;
    margin-top: 128px;
  }
  
  img { height: 100% }
  @media only screen and (max-width: 800px) {
    width: 90%;
  }
}

.section-5_funnel {
  max-width: 1555px;
  background-color: #fff;
  width: 100%;
  position: relative;
  background-image: url('./Assets/FullFunnel.svg');
  height: 1300px;
  background-size: cover;
  padding: 250px 400px;

  .down-arrows {
    position: absolute;
    margin-left: -100px;
    margin-top: 50px;
    @media only screen and (max-width: 1251px) {
      margin-left: 0;
      width: 100px;
      height: 100px;
      margin-top: 750px;
      margin-left: 60%;
    }
  }

  .pressure-tested {
    position: absolute;
    margin-left: 750px;
    @media only screen and (max-width: 1251px) {
      display: none;
    }
  }

  h1 {
    width: 400px;
    font-weight: bold;
    font-size: 4.5rem;
    color: white;
    z-index: 1000;
    text-align: right;
    @media only screen and (max-width: 1251px) {
      font-size: 3rem;
      text-align: left;
      margin-left: 15px;
      margin-top: 30px;
    }
  }

  @media only screen and (max-width: 1251px) {
    padding: 0;
    padding-top: 170px;
    background-position: 37%;
    margin-top: 50px;
  }
}

.section-5_list {
  margin-top: 64px;
  width: 400px;
  list-style: none;
  text-align: right;
  color: white;
  
  li { 
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 10px; 
  }

  @media only screen and (max-width: 1251px) {
    text-align: left;
    margin-left: 20px;
  }
}

// ==== ==== ==== SECTION 6 ==== ==== ====
// ==== ==== ==== SECTION 6 ==== ==== ====

.section-6 {
  position: relative;
  display: flex;
  flex-flow: column;
  max-width: 800px;
  margin-top: 150px;
  font-weight: bold;

  p {
    width: 100%;
    padding: 32px;
    font-size: 2.4rem;
  }

  input, textarea { 
    border: 8px solid #001AB1;
    border-radius: 5px;
    // height: 80px; 
    margin-bottom: 32px;
    padding: 15px;
    font-size: 1.8rem;
    // font-size: 12px;
    font-family: inherit;
    transition: .3s all;
    &:focus {
      border-color: #1a50e4;
      text-decoration: none;
      transition: .3s all;
    }
    @media only screen and (max-width: 800px) {
      font-size: 18px;
      padding: 10px;
    }
  }
  
  textarea {
    height: 300px;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
    margin-top: 75px;
  }
}

.section-6_ball {
  // position: absolute;
  // top: 95px;
  // right: 80px;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  align-self: flex-end;
  margin-top: -100px;
  margin-right: -20px;
  @media only screen and (max-width: 800px) {
    width: 70px;
    height: 70px;
    margin-top: -70px;;
  }
}

::placeholder {
  color: #86a1ea;
}

.section-6_contact {
  border: 8px solid #001AB1;
  border-radius: 10px;
  width: 100%;
  padding: 32px 48px;
  display: flex;
  flex-flow: column;
  @media only screen and (max-width: 800px) {
    margin-top: 30px;
    width: 95%;
  }
}

.section-6_logo {
  width: 150px;
  margin-bottom: 32px;
}

.contact_bottom {
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  font-weight: bold;

  button {
    background: #001AB1;
    color: white;
    padding: 16px;
    font-size: 2rem;
    font-weight: bold;
    border: none;
    transition: .3s all;
    border-radius: 5px;
    min-width: 150px;
    &:hover {
      background-color: #1a50e4;
      cursor: pointer;
      transition: .3s all;
    }
    &:focus {
      background-color: #86a1ea;
      transition: .3s all;
    }
    @media only screen and (max-width: 800px) {
      margin-bottom: 10px;
    }
  }

  p {
    @media only screen and (max-width: 800px) {
      text-align: center;;
    }
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }

}