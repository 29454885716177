.footer {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.footer_words {
  margin-top: 120px;
  width: 800px;
  display: flex;
  flex-flow: column;
  text-align: right;
  margin-bottom: 64px;

  h3 {
    text-align: center;
    margin-bottom: 64px;
    @media only screen and (max-width: 800px) {
      font-size: 40px;
    }
  }

  img {
    width: 180px;
    align-self: flex-end;
  }

  i {
    margin: 48px 0;
    font-size: 2rem;
  }

  span {
    margin-left: 24px;
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 800px) {
    width: 90%;
  }
}

.footer_slogan {
  font-size: 3.7rem;
  font-weight: bold;
}

.footer_socials {
  margin-top: -30px;
  margin-bottom: 20px;
}

.footer_socialLink {
  transition: all 0.3s;
}

.footer_socialLink img {
  width: 50px;
  height: 50px;
}

.footer_socialLink:hover {
  filter: opacity(0.6);
  transition: all 0.3s;
}

.footer_copyright {
  margin-top: 24px;
  color: blue;
}

.footer_balls {
  width: 100%;
  @media only screen and (max-width: 800px) {
  } 
}