.header {
  display: flex;
  align-items: center;
  margin-top: 36px; margin-left: 64px;
  z-index: 100000000;
  position: absolute;
  @media only screen and (max-width: 1251px) {
    margin-top: 10px; margin-left: 10px;
  }
}

.header_logo {
  width: 150px;
  margin-right: 64px;
  @media only screen and (max-width: 1251px) {
    width: 70px;
  }
}

.header_nav {
  display: flex;
  // justify-content: space-between;
  width: 525px;
  
  a {
    font-size: 1.1rem;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    transition: .3s all;
    text-decoration: none;
    color: #1a50e4;
    margin-right: 20px;

    &:hover { 
      background: $orange;
      color: white;
      cursor: pointer;
      transition: .1s all;
    }
    @media only screen and (max-width: 800px) {
      // display: none;
      font-size: 12px;
    }
  }
}